























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import './scss/FiltersDateRange.scss';

@Component({
    name: 'FiltersDateRange',
    components: {
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
    }
})

export default class FiltersCheckboxes extends Vue {
    @Prop({ default: '' }) title!: string;
    @Prop({ default: ['От','До'] }) titles!: string[];

    dateFrom = null;
    dateTo = null;

    onChange() {
        this.$emit('change-date', {
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
        });
    }

    resetDates() {
        this.dateFrom = null;
        this.dateTo = null;
    }

    changeDataDate(dateRange) {
        if (dateRange.section === this.title) {
            this.dateFrom = dateRange.datesRange.dateFrom;
            this.dateTo = dateRange.datesRange.dateTo;
        }
    }
}
